import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  const goBack = () => window.history.back()
  return (
    <Layout>
      <div>
        <h1>Oooops!</h1>
        <p>It looks like that page can't be found.</p>
        <p>
          Try using the navigation at the top of the page, or going{' '}
          <button type="button" class="link" onClick={goBack}>
            back
          </button>
          .
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
